module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chillery - Silesia Term","short_name":"Chillery - Silesia Term","start_url":"/","background_color":"#292929","theme_color":"#292929","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WTV27BH"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
